<template>
  <b-modal
    id="medical-records-modal"
    style="padding: 0;"
    @show="resetModulesFilter"
    hide-header
    hide-footer
    centered
    size="xl"
  >
  <div class="history-container" id="history-container">
    <AttendanceHistory :patientId="patientId" hideBurger/>
  </div>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getAllModules } from '@/utils/modulesHelper.js'
import AttendanceHistory from '@/layouts/AttendanceHistory'

export default {
  name: "MedicalRecordsModal",
  components: { AttendanceHistory},
  computed: {
    ...mapState({
      modules: state => state.attendance.history.modules,
    }),
  },

  props: {
      patientId: String,
  },
  methods: {
    ...mapActions('attendance/history', ['setModules']),
    resetModulesFilter () {
      this.setModules(getAllModules())
    }
  }
}
</script>

<style lang="scss" scoped>

#medical-records-modal {
  font-family: 'Nunito Sans';
  font-style: normal;
  color: var(--type-active);
  background-color: #ffffff;
  padding: 0 !important;
  .history-container {
    background: #ffffff;
    overflow-y: auto;
    border-left: 1px solid var(--neutral-200);
    height: 90vh;
    overflow-x: hidden !important;
  }
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}

</style>


